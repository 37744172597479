export const fetchDataPEPSimulation = (setState, pageSize) => {
  const timeStamp = new Date();
  const seconds = timeStamp.getSeconds();
  const milisec = timeStamp.getMilliseconds();
  const code2 = seconds < 10 ? `0${seconds}` : seconds;
  const newItem = {
    diastolicArterialPressure: '130.00',
    heartRate: '65.00',
    meanArterialPressure: '80.00',
    oxygenSaturation: '98.00',
    carbonDioxide: '38.00',
    referenceTimestamp: new Date().toISOString(),
    respiratoryRate: '20.00',
    systolicArterialPressure: '80.00',
    temperature: '36.80',
    vitalSigns: `3473e4ab-7c77-4682-a${code2}d-c${milisec}cc9259bc7a`,
  };

  setState((prev) => {
    const newArray = [newItem, ...prev.entries];
    if (newArray.length > pageSize) newArray.pop();
    return {
      ...prev,
      entries: newArray,
    };
  });
};

export const sendDataPEPSimulation = (setState, idsToRemove) => {
  setState((prevState) => {
    const updatedEntries = prevState.entries.filter((entry) => (
      !idsToRemove.includes(entry.vitalSigns)
    ));
    return {
      ...prevState,
      entries: updatedEntries,
    };
  });
};

export const getPep = {
  GetPepConfig: () => ({
    getPepConfig: {
      monitor: 15,
      infusion: 30,
      air: 30,
      hospitals: [
        {
          id: 1,
          label: 'Hospital Elizabeth Blackwell',
          value: 'rede_carenet/hospital_elizabeth_blackwell',
          __typename: 'HospitalSelect',
        },
        {
          id: 2,
          label: 'Hospital São Márcio',
          value: 'rede_carenet/hospital_sao_marcio',
          __typename: 'HospitalSelect',
        },
      ],
      __typename: 'PepConfig',
    },
  }),
  UpdatePepConfig: ({ onCompleted }) => [() => onCompleted(), { loading: false }],
  GetPep: ({ page }) => {
    if (page === 1) {
      return {
        getPep: {
          entriesCount: 11,
          pageNumber: 0,
          pageSize: 10,
          pagesCount: 2,
          pepStatus: '',
          entries: [
            {
              diastolicArterialPressure: '140.00',
              heartRate: '105.00',
              meanArterialPressure: '80.00',
              oxygenSaturation: '95.00',
              carbonDioxide: '35.00',
              referenceTimestamp: '2023-02-04T01:03:03.107Z',
              respiratoryRate: '17.00',
              systolicArterialPressure: '100.00',
              temperature: '36.50',
              vitalSigns: '3473e4aa-7c69-4682-a11b-c2cc9219bc2e',
            },
          ],
        },
      };
    }
    return {
      getPep: {
        entriesCount: 11,
        pageNumber: 0,
        pageSize: 10,
        pagesCount: 2,
        pepStatus: '',
        entries: [
          {
            diastolicArterialPressure: '140.00',
            heartRate: '105.00',
            meanArterialPressure: '80.00',
            oxygenSaturation: '95.00',
            carbonDioxide: '37.00',
            referenceTimestamp: '2023-02-04T01:03:03.107Z',
            respiratoryRate: '17.00',
            systolicArterialPressure: '100.00',
            temperature: '36.50',
            vitalSigns: '3473e4aa-7c69-4682-a11b-c2cc9219bc2e',
          },
          {
            diastolicArterialPressure: '141.00',
            heartRate: '107.00',
            meanArterialPressure: '83.00',
            oxygenSaturation: '94.00',
            carbonDioxide: '35.00',
            referenceTimestamp: '2023-01-24T15:31:00.530Z',
            respiratoryRate: '18.00',
            systolicArterialPressure: '106.00',
            temperature: '36.53',
            vitalSigns: '4601b979-0d2c-405b-811a-7bc43e4af1c7',
          },
          {
            diastolicArterialPressure: '149.00',
            heartRate: '126.00',
            meanArterialPressure: '114.00',
            oxygenSaturation: '82.00',
            carbonDioxide: '38.00',
            referenceTimestamp: '2023-01-24T15:10:00.997Z',
            respiratoryRate: '24.00',
            systolicArterialPressure: '168.00',
            temperature: '36.84',
            vitalSigns: '4229ce27-76e9-4e25-abca-44334b7b7d85',
          },
          {
            diastolicArterialPressure: '147.00',
            heartRate: '122.00',
            meanArterialPressure: '107.00',
            oxygenSaturation: '85.00',
            carbonDioxide: '40.00',
            referenceTimestamp: '2023-01-24T14:55:00.988Z',
            respiratoryRate: '22.00',
            systolicArterialPressure: '154.00',
            temperature: '36.77',
            vitalSigns: 'c3ae8534-f412-4bd2-8944-85ac6d684727',
          },
          {
            diastolicArterialPressure: '145.00',
            heartRate: '117.00',
            meanArterialPressure: '99.00',
            oxygenSaturation: '88.00',
            carbonDioxide: '35.00',
            referenceTimestamp: '2023-01-24T14:40:00.861Z',
            respiratoryRate: '21.00',
            systolicArterialPressure: '138.00',
            temperature: '36.69',
            vitalSigns: 'faba50e3-20dc-476e-be11-a0ffff6376d7',
          },
          {
            diastolicArterialPressure: '143.00',
            heartRate: '112.00',
            meanArterialPressure: '91.00',
            oxygenSaturation: '91.00',
            carbonDioxide: '35.00',
            referenceTimestamp: '2023-01-24T14:25:00.702Z',
            respiratoryRate: '19.00',
            systolicArterialPressure: '122.00',
            temperature: '36.61',
            vitalSigns: '9c5d5688-6795-4fbc-a42c-ec177106e308',
          },
          {
            diastolicArterialPressure: '142.00',
            heartRate: '109.00',
            meanArterialPressure: '87.00',
            oxygenSaturation: '93.00',
            carbonDioxide: '37.00',
            referenceTimestamp: '2023-01-24T14:10:00.689Z',
            respiratoryRate: '18.00',
            systolicArterialPressure: '113.00',
            temperature: '36.57',
            vitalSigns: '009f1a1a-8949-4a7c-850b-2971a89ac071',
          },
          {
            diastolicArterialPressure: '149.00',
            heartRate: '128.00',
            meanArterialPressure: '117.00',
            oxygenSaturation: '81.00',
            carbonDioxide: '35.00',
            referenceTimestamp: '2023-01-24T13:55:00.076Z',
            respiratoryRate: '24.00',
            systolicArterialPressure: '174.00',
            temperature: '36.87',
            vitalSigns: '48a02e69-6430-48bc-b91d-ceea96b0263d',
          },
          {
            diastolicArterialPressure: '148.00',
            heartRate: '126.00',
            meanArterialPressure: '113.00',
            oxygenSaturation: '82.00',
            carbonDioxide: '42.00',
            referenceTimestamp: '2023-01-24T13:40:00.074Z',
            respiratoryRate: '24.00',
            systolicArterialPressure: '167.00',
            temperature: '36.83',
            vitalSigns: 'bde4a562-ae3f-46b0-82b0-f23214eec420',
          },
          {
            diastolicArterialPressure: '141.00',
            heartRate: '106.00',
            meanArterialPressure: '82.00',
            oxygenSaturation: '94.00',
            carbonDioxide: '22.00',
            referenceTimestamp: '2023-01-24T13:24:00.999Z',
            respiratoryRate: '17.00',
            systolicArterialPressure: '104.00',
            temperature: '36.52',
            vitalSigns: '64c142bd-f109-4963-b2d9-61b4b591d0d0',
          },
        ],
      },
    };
  },
  SendPep: ({ onCompleted }) => {
    const _ = () => [onCompleted(), { loading: false }];
    return [_, { loading: false }];
  },
  UpdateVitalSignInPep: ({ onCompleted }) => {
    const _ = () => [onCompleted(), { loading: false }];
    return [_, { loading: false }];
  },
  ManualSample: (onCompleted) => [() => [onCompleted, { loading: false }], { loading: false }],
};
